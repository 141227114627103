import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { ConfiguratorContext } from '../contexts/ConfiguratorContext';
import { calculateTotalPrice } from "../helpers/PriceHelper";
import "./Price.scss";
import { useGAEventTracking } from "../helpers/TrackingHelper"
import { DataContext } from '../contexts/DataContext';


type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

const numberFormat = (value: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 0
  }).format(value);

export const Price: FC = () => {
    const { currentConfiguration } = useContext(ConfiguratorContext);
    const { scene } = useContext(DataContext);
    const [totalPrice, setTotalPrice] = useState("");
    const gaEventTracking = useGAEventTracking("Iframe");

    const trackBookClick = () => {
      gaEventTracking("Click","Bookmöte");
      window.open("https://videomeeting.vedum.se/","_blank");
    }
  
    useEffect(() => {  
        setTotalPrice(numberFormat(calculateTotalPrice(scene, currentConfiguration)).replace(",","."));
    }, [currentConfiguration]);

    return <div className='price'>
        <div className="price-value">
            <span>Ca {totalPrice} kr</span>
        </div>
        <a className='book' onClick={() => trackBookClick()} target="_blank"><FontAwesomeIcon size="lg" icon={["fas","book"]}></FontAwesomeIcon><span>Boka köksmöte</span></a>
    </div>
}

export default Price;


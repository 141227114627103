import { IConfiguration } from "../model/Configuration";

export const GenerateConfigUrl = (configuration: IConfiguration) => {

    if (configuration.options.length === 0) {
        return "";
    }

    let optionsStrings: string[] = [];
    var config = configuration.options.sort((a, b) => a.configurable > b.configurable ? -1 : 1);

    for (let index = 0; index < config.length; index++) {
        const element = config[index];
        optionsStrings.push(`&${element.configurable.id}=${element.color?.id ?? element.option.id}`);
    }

    return `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':'+window.location.port : ''}?scene=${configuration.scene}${optionsStrings.join('')}`;
}

export const GenerateUrl = (configuration: IConfiguration, showLogo: boolean) => {
    if (configuration.options.length === 0) {
        return "";
    }

    let optionsStrings: string[] = [];

    var config = configuration.options.sort((a, b) => a.configurable > b.configurable ? -1 : 1);

    for (let index = 0; index < config.length; index++) {
        const element = config[index];
        optionsStrings.push(`&${element.configurable.id}=${element.color?.id ?? element.option.id}`);
    }

    return `/api/scenes/${configuration.scene}/render?angle=${configuration.angle}${optionsStrings.join('')}&logo=${showLogo}`;
}

export const GenerateAngleUrl = (configuration: IConfiguration, angle: string, showLogo: boolean) => {
    if (configuration.options.length === 0) {
        return "";
    }

    let optionsStrings: string[] = [];

    var config = configuration.options.sort((a, b) => a.configurable > b.configurable ? -1 : 1);

    for (let index = 0; index < config.length; index++) {
        const element = config[index];
        optionsStrings.push(`&${element.configurable.id}=${element.color?.id ?? element.option.id}`);
    }

    return `/api/scenes/${configuration.scene}/render?angle=${angle}${optionsStrings.join('')}&logo=${showLogo}`;
}
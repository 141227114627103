import { FC, useState } from "react";
import ActionMenu from "./ActionMenu";
import AngleMenu from "./AngleMenu";
import Menu from "./Menu";
import { MobileTurn } from "./MobileTurn";
import { Modal } from "./Modal";
import Visualization from "./Visualization";

export const Configurator: FC = () => {

    const [showPresetModal, setShowPresetModal] = useState(true);

    const onCloseModal = () => {
        setShowPresetModal(false);
    }
    
    return <div>
        <Visualization />
        <Menu/>  
        <AngleMenu />    
        <ActionMenu/>
        <Modal show={showPresetModal} header="Välkommen till vår kökskonfigurator!" description="<p>Verktyget ger dig hjälp och inspiration på vägen till ditt nya drömkök. 
        I menyn till vänster kan du göra dina val och testa olika kombinationer av våra luckor, bänkskivor, kulörer och handtag.</p></br>
        <p>När du är klar får du ett cirkapris och kan spara bilder samt en PDF med dina val som du kan ta med dig till ditt möte med oss. Priset är inklusive moms och exklusive lådinredning och vitvaror. Vid val av bänkskiva i natur-och kompositsten kan det tillkomma extra kostnader för måttagning och montering. 
        Eftersom detta är en digital lösning reserverar vi oss för att träslag, färger och andra ytor kan avvika från verkligheten.</p></br>
        <p>Du kan träffa oss i någon av våra utställningar eller via vår digitala konsultation.</p>
        <p>Du kan också spara ner dina val i en inspirerande moodboard som du gärna får dela på
        Instagram och tagga oss på @vedumkokochbad.</p>
        <p>Lycka till!</p>" onCloseModal={() => onCloseModal()} />
        <MobileTurn/>
    </div>
}

export default Configurator;
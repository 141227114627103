import React, { useContext } from 'react';
import './App.scss';
import { DataContext } from './contexts/DataContext';
import { ConfiguratorContext, ConfiguratorProvider } from './contexts/ConfiguratorContext';
import Configurator from './components/Configurator';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas, faPencil, faArrowLeft, faClose, faMaximize, faFilePdf, faImage, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

library.add(fas, faPencil, faArrowLeft, faClose, faMaximize, faFilePdf, faImage, faCircleNotch);

function App() {
  const { options, scene } = useContext(DataContext);
  
  return (
      <ConfiguratorProvider options={options} scene={scene}>
          <ConfiguratorContext.Consumer>
              {() =>
                  <div className="vedum-configurator">
                      <div className="vedum-container">
                          <Configurator />
                      </div>
                  </div>}
          </ConfiguratorContext.Consumer>
      </ConfiguratorProvider>
  );
}

export default App;


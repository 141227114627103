import { FC, ReactEventHandler, SyntheticEvent, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ConfiguratorContext } from "../contexts/ConfiguratorContext";
import { DataContext } from "../contexts/DataContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IChangeable, IColor, IConfigurableRef, IOption } from "../model/CustomizerData";
import './Menu.scss';
import KitchenMenu from "./KitchenMenu";
import { Modal } from "./Modal";
import { setPriority } from "os";


export const Menu: FC = () => {
    const { currentConfiguration, changeOption, changeOptions, open, changeOpen, openSub, changeOpenSub, selectedConfigurable, changeSelectedConfigurable, selectedOption, changeSelectedOption, loadPreset, showPreset, changeShowPreset, selectedPreset } = useContext(ConfiguratorContext);
    const { options, scene } = useContext(DataContext);

    const [showPresetModal, setShowPresetModal] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    const availableTypes = useMemo(() => {

        const o = options.find(e => e.id == selectedConfigurable?.optionsID);
        let uniqueTypes = o?.options.map(e => e.type).filter((value, index, self) => { return self.indexOf(value) === index }); 
        return uniqueTypes ?? [];

    }, [selectedConfigurable]);

    const availableColorTypes = useMemo(() => {

       let uniqueTypes;
        if (selectedOption?.colors) {
           uniqueTypes = selectedOption?.colors.map(e => e.type).filter((value, index, self) => { return self.indexOf(value) === index });     
        }
        return uniqueTypes ?? [];

    }, [selectedOption]);

    const availableOptions = useMemo(() => {
        const o = options.find(e => e.id == selectedConfigurable?.optionsID);
        return o?.options ?? [];
    }, [selectedConfigurable]);

    const availableColors = useMemo(() => {
        return selectedOption?.colors ?? [];
    }, [selectedOption]);

    const isSubMenu = useMemo(() => {
        return selectedConfigurable?.optionsID === "layout" ?? false;
    }, [selectedConfigurable]);

    const selectedValue = useMemo(() => {
        if (selectedConfigurable) {
            const configurable = currentConfiguration.options.find(e => e.configurable.id === selectedConfigurable.id);

            const o = options.find(e => e.id == selectedConfigurable?.optionsID);
            const option = o?.options.find(e => e.id == configurable?.option.id || e.colors?.some(c => c.id == configurable?.option.id));

            if (selectedOption && selectedOption.colors) {              
                return selectedOption.id;
            }

            return option?.id;
        }
    }, [selectedOption, selectedConfigurable, currentConfiguration]);

    const selectedSubValue = useMemo(() => {
        if (selectedConfigurable) {
            const configurable = currentConfiguration.options.find(e => e.configurable.id == selectedConfigurable.id);
         
            if (selectedOption) {
                var selectedColor = selectedOption.colors?.find(x => {return x.id.indexOf(configurable?.color?.id.substring(configurable?.color?.id.lastIndexOf("_"), configurable?.color?.id.length) ?? "") > 0 });
                if (selectedColor) {                    
                    return selectedColor;
                }
                return configurable?.color;
            }
        }
    }, [selectedOption, selectedConfigurable, currentConfiguration]);

    useEffect(() => {
        if (open === false && selectedConfigurable?.id !== "front") {
            changeSelectedConfigurable(null);
        }
    }, [open]);   

    useEffect(() => {    
        if (showPreset) {
            setShowPresetModal(true); 
        }   
    }, [selectedPreset]);

    const back = () => {
        if (selectedOption?.colors) {
            changeSelectedConfigurable(null);
            changeSelectedOption(null);
        }
        else if (selectedOption) {
            changeSelectedConfigurable(null);
            changeSelectedOption(null);
        } else if (selectedConfigurable) {
            changeSelectedConfigurable(null);
        } else if (showPreset) {
            changeShowPreset(false);
        } else {
            changeOpen(false);
        }
    }

    const close = () => {
      //  changeSelectedConfigurable(null);
     //   changeSelectedOption(null);
        changeOpen(false);
    }

    const closeSub = () => {
        changeOpenSub(false);
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    
    const handleClickOutside = (event: any) => {      
        if (ref.current && !ref.current.contains(event.target)) {
            close();
            closeSub();
        }
    }

    const selectConfigurable = (configurable: IConfigurableRef) => {

        if (configurable) {
            changeSelectedConfigurable(configurable);

            const configurableOption = currentConfiguration.options.find(e => e.configurable.id == configurable.id);

            if (configurableOption) {
                const o = options.find(e => e.id == configurable?.optionsID);
                const option = o?.options.find(e => e.id == configurableOption?.option.id || e.colors?.some(c => c.id == configurableOption?.option.id));
                if (option) {
                    changeSelectedOption(option);
                    
                    if (option.colors) {
                        changeOpenSub(true);
                    }
                }
            }
        }
    }   

    const selectOption = (option: IOption) => {

        if (option.colors) {
            if (selectedConfigurable) {
                const configurable = currentConfiguration.options.find(e => e.configurable.id == selectedConfigurable.id);
                var selectedColor = option.colors?.find(x => {return x.id.indexOf(configurable?.color?.id.substring(configurable?.color?.id.lastIndexOf("_"), configurable?.color?.id.length) ?? "") > 0 });
                if (!selectedColor) {
                    selectedColor = option.colors[0];
                }

                let changeableOptions = [] as Array<IChangeable>;
                
                //Handleless    
                const handleOption = currentConfiguration.options.find(e => e.configurable.id == "handle");
                const handleConfigurable = scene.configurables.find(e => e.id === "handle");    
            
                if (handleConfigurable) {
                    if (option.handleless || handleOption?.option.handleless) {
                        changeableOptions.push({configurable: handleConfigurable, option: (handleOption?.option.handleless) ? handleOption?.option : null});                         
                    } else {                       
                        const o = options.find(x => x.id === handleConfigurable?.optionsID);
                        const defaultOption = o?.options.find(x => x.id === handleConfigurable?.defaultOption);

                        if (defaultOption) {  
                            changeableOptions.push({configurable: handleConfigurable, option: handleOption?.option ?? defaultOption});
                        } 
                    }
                }

                changeableOptions.push({configurable: selectedConfigurable, option: option, color: selectedColor})

                changeOptions(changeableOptions);  
                changeSelectedOption(option);
                changeOpenSub(true);
            }
        } else if (selectedConfigurable) {    
            changeOption(selectedConfigurable, option);            
        }        
    }

    const selectColor = (color: IColor) => {
        if (selectedConfigurable && selectedOption) {
            changeOption(selectedConfigurable, selectedOption, color);
        }
    }

    const thumbnailImage = (configurable: IConfigurableRef | null, option?: IOption | null) => {
        return `api/thumbnail/${configurable?.id}/${option?.id}`;
    }

    const thumbnailColorImage = (configurable: IConfigurableRef | null, option?: IColor | null) => {
        return `api/thumbnail/${configurable?.id}/colors/${option?.id}`;
    }

    const thumbnailPresetImage = (preset: string) => {
        return `api/thumbnail/presets/${preset}`;
    }

    const placeholderImage = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = "https://via.placeholder.com/75x141.png";
        e.currentTarget.onerror = null;
    }

    var optionClass = "menu-options";
    if (selectedConfigurable != null && selectedOption === null) {
        optionClass += " configurable-select"
    }
    if (selectedOption != null) {
        optionClass += " option-select"
    }

    const onCloseModal = () => {
        setShowPresetModal(false);
    }

    const onImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
        e.currentTarget.classList.add("show");
    }

    const availableMainMenuOptions = () => {

        const configurable = currentConfiguration.options.find(e => e.configurable.id == 'front');
        if (configurable) {
            if (configurable.option.handleless) {
                return [{id: 'front', sort: 1},{id: 'tabletop', sort: 2},{id: 'layout',sort: 3}];
            }
        }       

        return [{id: 'front', sort: 1},{id: 'handle', sort: 2},{id: 'tabletop', sort: 3},{id: 'layout',sort: 4}];
    }

    const openMenu = (open: boolean) => {

        if (selectedConfigurable?.id === "front") {
            if (open) {
                changeSelectedConfigurable(null);
            }
        }

        changeOpen(open);
    
    }

    return <div>
        <button className="menu-toggle" onClick={() => { openMenu(!open); changeSelectedOption(null) }}><FontAwesomeIcon size="2x" icon={["fas", "pencil"]} /><span>Gör dina val</span></button>

        <Modal show={showPresetModal} header={selectedPreset?.name ?? ""} description={selectedPreset?.description ?? ""} onCloseModal={() => onCloseModal()} />
        <div className="menu-wrapper" ref={ref}>
        {open && 

            <div className="menu-container">
                <button className="menu-close" onClick={close}><FontAwesomeIcon icon={["fas", "close"]} /></button>

                {(selectedConfigurable != null || showPreset) ? <button onClick={back} className="menu-back"><span><FontAwesomeIcon icon={["fas", "arrow-left"]} /></span><span>{(showPreset) ? "Stilar" : selectedConfigurable?.name}</span></button> : null}

                {!isSubMenu ?
                    <div className={optionClass}>

                        {(selectedConfigurable == null && !showPreset) ? <button key={'preset'} className="menu-main" onClick={() => changeShowPreset(true)}><div className="menu-img-wrapper"><img src={thumbnailPresetImage('presets')} onLoad={(e) => onImageLoad(e)} onError={(ex) => { placeholderImage(ex) }} /></div><span>Vilken stil passar mig?</span></button> : null}

                        {(selectedConfigurable == null && !showPreset) ? scene.configurables.filter(f => { return availableMainMenuOptions().sort((a,b) => { return a.sort > b.sort ? 1 : -1}).map(x => x.id).includes(f.optionsID) }).map(e => <button key={e.id} className="menu-main" onClick={() => selectConfigurable(e)}><div className="menu-img-wrapper"><img src={thumbnailImage(e)} onLoad={(e) => onImageLoad(e)} onError={(ex) => { placeholderImage(ex) }} /></div><span>{e.name}</span></button>) : null}

                        {(showPreset) ? scene.presets.map(e => <button key={e.id} onClick={() => loadPreset(e)} className={(e.id == selectedPreset?.id) ? 'menu-preset selected' : 'menu-preset'} ><div className="menu-img-wrapper"><img src={thumbnailPresetImage(e.id)} onLoad={(e) => onImageLoad(e)} onError={(ex) => { placeholderImage(ex) }} /></div><span>{e.name}</span></button>) : null}

                        {(selectedConfigurable != null && !isSubMenu) ?

                            availableTypes.map(type => {
                                return (
                                    [<div key={type} className="menu-option-header">{type ?? '\u00A0'}</div>,
                                    availableOptions.sort((a,b) => a.sort > b.sort ? 1 : -1).filter(f => { return f.type === type }).map(e => <button key={e.id} className={(e.id == selectedValue) ? 'menu-option selected' : 'menu-option'} onClick={() => selectOption(e)}><div className="menu-img-wrapper"><img src={thumbnailImage(selectedConfigurable, e)} onLoad={(e) => onImageLoad(e)} onError={(ex) => { placeholderImage(ex) }} /></div><span>{e.name}</span></button>)]
                                )
                            }) : null
                        }
                    </div> 
                    : null
                }

                <KitchenMenu key="kitchenmenu" visible={isSubMenu} configurables={["layout", "drawers", "backsplash", "wallcolor", "floorcolor"]} />
            </div>
        }
            {openSub && (selectedOption != null && selectedOption.colors) ?
                <div className="menu-sub-container">
                     <button className="menu-close" onClick={closeSub}><FontAwesomeIcon icon={["fas", "close"]} /></button>

                    <button className="menu-back"><span>&nbsp;</span><span>Kulör</span></button>

                    <div className={optionClass}>

                        {availableColorTypes.map(type => {
                                return (
                                    [<div key={type} className="menu-option-header">{type ?? '\u00A0'}</div>,
                                    availableColors.sort((a,b) => a.sort > b.sort ? 1 : -1).filter(f => {return f.type === type}).map(e => <button key={e.id} onClick={() => selectColor(e)} className={e.id == selectedSubValue?.id ? 'menu-option selected' : 'menu-option'} ><div className="menu-img-wrapper"><img src={thumbnailColorImage(selectedConfigurable, e)} onLoad={(e) => onImageLoad(e)} onError={(ex) => { placeholderImage(ex) }}  /></div><span>{e.name}</span></button>)]
                                )                                
                            })
                        }
                        
                    </div>
                </div>
                : null
            }
        </div>
        
    </div>
}

export default Menu;
import { FC, useEffect, useState, useContext } from "react";
import { ConfiguratorContext } from "../contexts/ConfiguratorContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./ActionMenu.scss";
import { GenerateUrl } from "../helpers/RenderHelper";
import { renderPDF } from "../helpers/PDFHelper";
import { IConfiguration } from "../model/Configuration";
import { DataContext } from "../contexts/DataContext";

export const ActionMenu: FC = () => {

    const { currentConfiguration } = useContext(ConfiguratorContext);
    const { scene } = useContext(DataContext)
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [creatingPDF, setCreatingPDF] = useState(false);
    const [creatingImage, setCreatingImage] = useState(false);

    const fullscreen = () => {

        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    };

    const downloadPDF = (configuration: IConfiguration) => {
       
        setCreatingPDF(true);
      
        renderPDF(scene, configuration).then((blob) => {
           
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `VedumPDF.pdf`,
                );

                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);

                setCreatingPDF(false);
           
        });
    
    };

    const downloadImage = () => {

        setCreatingImage(true);
       
        fetch(GenerateUrl(currentConfiguration, true), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/jpeg',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {

                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `VedumImage.jpg`,
                );

                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);

                setCreatingImage(false);

            });        
    };

    // Watch for fullscreenchange
    useEffect(() => {
        function onFullscreenChange() {
            setIsFullscreen(Boolean(document.fullscreenElement));
        }
        document.addEventListener('fullscreenchange', onFullscreenChange);
        return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
    }, []);

    return <div className="action-menu">
        <div className="action-menu-top">
            <button onClick={() => fullscreen()} className="action-button action-fullscreen"><FontAwesomeIcon size="2x" icon={["fas", isFullscreen ? "minimize" : "maximize"]} /></button>
        </div>
        <div className="action-menu-bottom">
            <button onClick={() => downloadPDF(currentConfiguration)} className="action-button action-fullscreen" disabled={creatingPDF}><FontAwesomeIcon className={creatingPDF ? "spinner" : ""} size="2x" icon={["fas", creatingPDF ? "circle-notch": "file-pdf"]} /></button>
            <button onClick={() => downloadImage()} className="action-button action-fullscreen" disabled={creatingImage}><FontAwesomeIcon className={creatingImage ? "spinner" : ""}  size="2x" icon={["fas", creatingImage ? "circle-notch": "image"]} /></button>
        </div>
    </div>

}

export default ActionMenu;
import { FC, SyntheticEvent, useContext, useMemo, Fragment } from "react";
import { ConfiguratorContext } from "../contexts/ConfiguratorContext";
import { DataContext } from "../contexts/DataContext";
import { IConfigurable, IConfigurableRef, IOption } from "../model/CustomizerData";
import "./KitchenMenu.scss";

interface KitchenMenuProps {
    visible: boolean,
    configurables: string[]
}

const KitchenMenu: FC<KitchenMenuProps> = (props) => {
    const { currentConfiguration, changeOption, open, changeOpen, selectedConfigurable, changeSelectedConfigurable, selectedOption, changeSelectedOption, loadPreset, showPreset, changeShowPreset, changeSelectedPreset, selectedPreset } = useContext(ConfiguratorContext);
    const { options, scene } = useContext(DataContext);

    const availableOptions = (configurable: IConfigurableRef | null) => {
        const c = scene.configurables.find(e => { return e.id === configurable?.optionsID });
        const o = options.find(x => x.id === c?.optionsID);
        let ao = o?.options;

        //Disable options
        if (configurable) {
            var disableOption = currentConfiguration.options.find(x => x.option.disables?.includes(configurable?.id));
            if (disableOption) {
                ao = [];
            }
        }

        return ao ?? [];
    };

    const availableConfigurables = useMemo(() => {
        return props.configurables.map(e => { return scene.configurables.find(f => { return f.id === e }) || null }) ?? [];
    }, [props.configurables]);

    const selectedValue = (configurable: IConfigurableRef | null) => {
        if (configurable) {
            const c = currentConfiguration.options.find(e => e.configurable.id === configurable.id);

            const o = options.find(e => e.id == configurable?.optionsID);
            const option = o?.options.find(e => e.id == c?.option.id || e.colors?.some(c => c.id == c?.id));
            return option?.id;
        }
    };

    const selectOption = (configurable: IConfigurableRef | null, option: IOption) => {
        if (option.colors) {
            changeSelectedOption(option);
        } else if (configurable) {
            changeOption(configurable, option);
        }
    };

    const thumbnailImage = (configurable: IConfigurableRef | null, option?: IOption | null) => {
        return `api/thumbnail/${configurable?.id}/${option?.id}`;
    }

    const placeholderImage = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = "https://via.placeholder.com/75x141.png";
        e.currentTarget.onerror = null;
    }

    const isToggle = (configurable: IConfigurableRef | null) => {
        const count = options.find(x => x.id === configurable?.optionsID)?.options?.length ?? 0;
        return (count < 3);
    }

    return props.visible ?
        <div className="kitchenmenu-options">
            {availableConfigurables.map(e => <div key={e?.optionsID} className="kitchenmenu-option-container">
                {!isToggle(e) ? <span>{e?.name}</span> : null}
                <div className="kitchenmenu-option-list">
                    {availableOptions(e).map(f =>
                        <Fragment key={f.name} >
                            {!isToggle(e) ? <button className={(f.id == selectedValue(e)) ? 'kitchenmenu-option selected' : 'kitchenmenu-option'} onClick={() => selectOption(e, f)}><div className="menu-img-wrapper"><img src={thumbnailImage(e, f)} onError={(ex) => { placeholderImage(ex) }} /></div><span>{f.name}</span></button> : null}
                            {isToggle(e) ? <div className="kitchenmenu-option-wrapper"><span>{f?.name}</span><button className={(f.id == selectedValue(e)) ? 'kitchenmenu-option selected' + (isToggle(e) ? " toggle" : "") : 'kitchenmenu-option' + (isToggle(e) ? " toggle" : "")} onClick={() => selectOption(e, f)}><div className="menu-img-wrapper"><img src={thumbnailImage(e, f)} onError={(ex) => { placeholderImage(ex) }} /></div></button></div> : null}
                        </Fragment>
                    )}
                </div>
            </div>)}
        </div> : null

}

export default KitchenMenu;
import { useContext } from "react";
import { IConfiguration } from "../model/Configuration";
import { IScene } from "../model/CustomizerData";

export const calculateTotalPrice = (scene: IScene, configuration: IConfiguration) => {
  let totalPrice: number = 0;

  var layoutId = configuration.options.find(x => { return x.configurable.id === "layout" })?.option.id;
  var drawersId = configuration.options.find(x => { return x.configurable.id === "drawers" })?.option.id;
  var backsplashId = configuration.options.find(x => { return x.configurable.id === "backsplash" })?.option.id;

  configuration.options.forEach((option) => {

    switch (option.configurable.id) {
      case "front":
      case "handle": {
        if (!option.color) {
          totalPrice += option.option?.price.find(x => { return x.id === layoutId + "_" + drawersId })?.value ?? 0;
        } else {
          totalPrice += option.color?.price.find(x => { return x.id === layoutId + "_" + drawersId })?.value ?? 0;
        }

      }
        break;
      case "tabletop": {
        totalPrice += option.option?.price.find(x => { return x.id === backsplashId })?.value ?? 0;
        totalPrice += option.option?.price.find(x => { return x.id === layoutId })?.value ?? 0;

      }
        break;
    }

    //Non-configurables - Configurables - (extras etc. that modify price)
    if (scene.nonconfigurables) {
      let nonconfigurables = scene.nonconfigurables.filter(x => { return x.options.filter(o => o.refConfigurable === option.configurable.id).length > 0 })
      nonconfigurables.forEach((nonconfigurable) => {

        let nonconfigurableOption = nonconfigurable.options.find(x => { return x.refTypes.includes(option.option.type) || x.refTypes.length === 0 })
        if (nonconfigurableOption) {
          totalPrice += nonconfigurableOption.price[0].value;
        }

      })
    }
  });

  //Non-configurables - General - (extras etc. that modify price)
  if (scene.nonconfigurables) {
    let nonconfigurables = scene.nonconfigurables.filter(x => { return x.options.filter(o => o.refConfigurable === "").length > 0 })
    nonconfigurables.forEach((nonconfigurable) => {

      let nonconfigurableOption = nonconfigurable.options.find(x => { return x.refTypes.length === 0 })
      if (nonconfigurableOption) {
        totalPrice += nonconfigurableOption.price[0].value;
      }

    })
  }

  return totalPrice;
}
